html {
  height: 100%;
  scroll-behavior: smooth; }

body {
  margin: 0;
  font-size: 12px;
  font-family: Calibri, sans-serif;
  height: 86%; }

* {
  box-sizing: border-box; }

button {
  cursor: pointer; }

input, select, button {
  font-size: 14px;
  outline: none;
  border: none; }
